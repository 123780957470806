<template>
    <div>
        <v-card class="border-radius-sm" :class="!$vuetify.theme.dark ? 'bg-upload' :''" flat>
            <div class="pa-2 d-flex justify-content-between">
                <v-btn :loading="$global.state.uploading" @click="handleFileImport" icon color="info">
                    <v-icon>fa-solid fa-paperclip</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <small class="red--text mt-2">{{ warnText }}</small>
                <input ref="uploader" class="d-none" type="file" :accept="accept" @change="onFileChanged" />
            </div>
            <!-- <v-divider></v-divider> -->
            <v-card-text v-show="vedioPreviews[0]">
                <v-card flat class="border-lg" v-for="(preivew, i) in vedioPreviews" :key="i">
                    <v-badge offset-x="15" right color="transparent">
                        <template v-slot:badge>
                            <v-btn @click="deleteFile(i)" icon dark x-small class="pink btn-delete-file">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <video controls autoplay width="100%">
                            <source :src="preivew" type="video/mp4" />
                        </video>
                        <small>{{ vedioFiles[0] ? `${(Math.round(+vedioFiles[i].size/1024)/1000).toFixed(2)}MB` : 0 }}</small>
                    </v-badge>
                </v-card>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    props: ["accept", "formData", "endPoint"],
    data() {
        return {
            warnText: "",
        };
    },

    computed: {
        ...mapState(["vedioFiles", "vedioPreviews"]),
    },

    methods: {
        deleteFile(i) {
            this.$store.commit("clearVedioFiles");
        },

        handleFileImport() {
            this.isSelecting = true;
            window.addEventListener(
                "focus",
                () => {
                    this.isSelecting = false;
                },
                {
                    once: true,
                }
            );
            this.$refs.uploader.click();
        },

        async onFileChanged(e) {
            let file = e.target.files[0];
            if (file && file["type"].split("/")[0] === "video") {
                this.warnText = "";
                await this.$store.commit("clearVedioFiles");
                this.vedioFiles.push(e.target.files[0]);
                this.vedioPreviews.push(URL.createObjectURL(e.target.files[0]));
            } else {
                this.$store.dispatch("toastification/setToast", {
                    message: `!! الرجاء تحديد فيديو`,
                    type: "warning",
                });
                this.warnText = "!! الرجاء تحديد فيديو";
            }
        },
    },
};
</script>

<style scoped>
.bg-upload {
    background: #f7f9fc;
}

.btn-delete-file {
    padding: 10px;
    font-weight: bold;
}
</style>
